<template>
    <div class="relative flex">
        <el-input ref="searchInput" class="w-64" placeholder="Snabbsökning" @keyup.native="inputChange" @clear="inputChange" v-model="query" clearable spellcheck="false" />
        <el-popover placement="top-start" title="Du kan söka efter" width="200" trigger="hover">
            <p class="my-1 py-2 px-4 bg-blue-50 hover:bg-blue-200 transition-colors rounded-lg cursor-pointer">
                Fråga (Svenska)
            </p>
            <p class="my-1 py-2 px-4 bg-blue-50 hover:bg-blue-200 transition-colors rounded-lg cursor-pointer">
                Taggar
            </p>
            <div class="absolute right-1 top-0.5" slot="reference">
                <i class="far fa-filter-list text-lg" />
            </div>
        </el-popover>
    </div>
</template>
<script>
export default {
    data() {
        return {
            query: "",
            timer: null,
        };
    },

    created() {},

    methods: {
        inputChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.$emit("change", this.query.trim());
            }, 1300);
        },
    },
};
</script>
